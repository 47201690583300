import React, { useState, useRef, useEffect } from "react";
import DropdownPortal from "./dropdownPortal/DropdownPortal";
import config from "../config";
import axios from "axios";
import EditIcon from "./icons/EditIcon";
import TrashIcon from "./icons/TrashIcon";
import DotsIcon from "./icons/DotsIcon";
import { useNavigate } from "react-router-dom";

const NavPrompt = ({
  chatName,
  setChatName,
  chatNames,
  setChatNames,
  chatID,
  setChatID,
  setChatLog,
  setHasPrinted,
  isActive,
  setActiveId,
  textAreaRef,
  llmLabel,
  llmId,
  llmConfig,
  setLlmLabel,
  setLlmId,
  setLlmConfig,
}) => {
  const navPromptHref = `navPrompt-${chatName.replace(/[^a-zA-Z0-9]/g, "-")}`;
  const CHAT_ENDPOINT = config.VERSION_ENDPOINT + "chat";
  const CHAT_SESSIONS_ENDPOINT = config.VERSION_ENDPOINT + "chat-session";
  const navigate = useNavigate();

  const [editing, setEditing] = useState(false);
  const [chatPromptName, setChatPromptName] = useState(chatName);
  const [chatNameCharacters, setChatNameCharacters] = useState(
    chatName.split("")
  );
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [buttonPosition, setButtonPosition] = useState({
    top: 0,
    left: 0,
    height: 0,
  });
  const chatNameContainer = useRef();
  const editButtonRef = useRef();

  // Sends chat session name update request to the server
  useEffect(() => {
    if (!editing && chatPromptName !== chatName) {
      axios
        .put(CHAT_SESSIONS_ENDPOINT + "/" + chatID, {
          session_name: chatPromptName,
        })
        .then(() => {
          setChatName(chatPromptName);
          setChatNameCharacters(chatPromptName.split(""));
          setChatNames(
            chatNames.map((chat) => {
              if (chat.id === chatID) {
                return { ...chat, name: chatPromptName };
              }
              return chat;
            })
          );
        });
    }
  }, [chatPromptName, editing]);

  // Function to load past chat logs from localStorage
  const loadChatLogs = async () => {
    setActiveId(chatID);
    setChatID(chatID);
    setChatName(chatName);
    setLlmLabel(llmLabel);
    setLlmId(llmId);
    setLlmConfig(llmConfig);

    axios.get(CHAT_ENDPOINT + "/" + chatID).then((response) => {
      const savedChatLog = response.data.map((resp) => {
        return {
          chatPrompt: resp["prompt"],
          botMessage: resp["response"],
          id: resp["id"],
          rating: resp["rating"],
          unhelpful: resp["unhelpful"],
          incorrect: resp["incorrect"],
          harmful: resp["harmful"],
          comment: resp["comment"],
          sources: resp["sources"],
        };
      });
      setChatLog(savedChatLog);
      setHasPrinted(true);
    });
    textAreaRef.current.focus();
  };

  // Scroll to the beginning of the container when editing mode is exited
  useEffect(() => {
    if (!editing && chatNameContainer.current) {
      chatNameContainer.current.scrollLeft = 0;
    }
    // handles enter key press to exit editing mode
    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        setEditing(false);
      }
    };
    if (editing) {
      window.addEventListener("keypress", handleKeyPress);
    } else {
      window.removeEventListener("keypress", handleKeyPress);
    }
    return () => {
      window.removeEventListener("keypress", handleKeyPress);
    };
  }, [editing]);

  const handleButtonClick = (event) => {
    event.stopPropagation();
    const rect = editButtonRef.current.getBoundingClientRect();
    setButtonPosition({ top: rect.top, left: rect.left, height: rect.height });
    setDropdownVisible(true);
  };

  const options = [
    { value: "rename", label: "Rename", icon: <EditIcon fill="gray" /> },
    {
      value: "delete",
      label: "Delete",
      icon: <TrashIcon fill="red" />,
      style: { color: "red" },
    },
  ];

  const onClickOptionHandler = (option) => {
    setDropdownVisible(false);
    if (option.value === options[0].value) {
      setEditing(true);
    } else if (option.value === options[1].value) {
      if (
        window.confirm("Are you sure you wish to delete this conversation?")
      ) {
        // Delete chat session
        axios.delete(CHAT_SESSIONS_ENDPOINT + "/" + chatID).then(() => {
          setChatNames(chatNames.filter((chat) => chat.id !== chatID));
        });

        // Navigate to new chat page if the current chat is deleted
        if (isActive) {
          navigate("/");
          loadChatLogs();
        }
      }
    }
  };
  const [hovered, setHovered] = useState(false);
  return (
    <div
      className={`navPrompt ${isActive || dropdownVisible ? "active" : ""}`}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <a href={`#${navPromptHref}`} onClick={() => loadChatLogs()}>
        <svg
          viewBox="0 0 16 16"
          xmlns="http://www.w3.org/2000/svg"
          fill="#ECECF1"
          stroke="#ECECF1"
          width={16}
          height={16}
        >
          <path
            fill="#ECECF1"
            fillRule="evenodd"
            d="M15 3.25A2.25 2.25 0 0 0 12.75 1h-9.5A2.25 2.25 0 0 0 1 3.25v11a.75.75 0 0 0 1.26.55l2.801-2.6a.75.75 0 0 1 .51-.2h7.179A2.25 2.25 0 0 0 15 9.75v-6.5zm-2.25-.75a.75.75 0 0 1 .75.75v6.5a.75.75 0 0 1-.75.75H5.572a2.25 2.25 0 0 0-1.531.6L2.5 12.53V3.25a.75.75 0 0 1 .75-.75h9.5z"
            clipRule="evenodd"
          />
        </svg>
        <p ref={chatNameContainer}>
          {editing ? (
            <input
              type="text"
              value={chatPromptName}
              onChange={(e) => {
                setChatPromptName(e.target.value);
                setChatNameCharacters(e.target.value.split(""));
              }}
              autoFocus
              onBlur={() => setEditing(false)}
            />
          ) : (
            chatNameCharacters.map((char, idx) => (
              <span key={idx} style={{ animationDelay: `${idx * 0.1}s` }}>
                {char}
              </span>
            ))
          )}
        </p>
      </a>
      <div>
        <button
          ref={editButtonRef}
          onClick={handleButtonClick}
          className={
            !editing && (hovered || dropdownVisible || isActive)
              ? "visible"
              : "hidden"
          }
        >
          <DotsIcon />
          {dropdownVisible && (
            <DropdownPortal
              options={options}
              position={buttonPosition}
              onClose={() => setDropdownVisible(false)}
              onClickOption={onClickOptionHandler}
            />
          )}
        </button>
      </div>
    </div>
  );
};

export default NavPrompt;
