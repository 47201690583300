import { useState, useEffect } from "react";
import BotResponse from "../BotResponse";
import Loading from "../Loading";
import { ThumbsUp, ThumbsDown, Copy, CheckIcon } from "./icons";
import { FeedbackModal } from "./feedbackModal.react";
import { styles } from "./styles";

import config from "../../config";
import axios from "axios";

const grayShade = "rgb(134,132,141)";
const greenShade = "rgb(102,188,51)";
const redShade = "#FE4A22";

export const ChatLogRow = (props) => {
  const {
    chat,
    chatId,
    llmId,
    llmLabel,
    chatLogRef,
    currentUser,
    hasPrinted,
    setHasPrinted,
    rating,
    unhelpful,
    incorrect,
    harmful,
    comment,
    userSuggestedAnswer,
  } = props;

  const [isCopied, setIsCopied] = useState(false);
  const [copiedMessageVisible, setCopiedMessageVisible] = useState(false);
  const [isThumbsUp, setIsThumbsUp] = useState(false);
  const [isThumbsDown, setIsThumbsDown] = useState(false);
  const [feedbackModalIsVisible, setFeedbackModalVisible] = useState(false);

  const CHAT_FEEDBACK_ENDPOINT = config.VERSION_ENDPOINT + "chat/" + chatId;

  const handleCopyClick = () => {
    setIsCopied(true);
    setCopiedMessageVisible(true);
    navigator.clipboard.writeText(chat.botMessage);

    const timer = setTimeout(() => {
      setCopiedMessageVisible(false);
      setIsCopied(false);
    }, 5000);

    return () => clearTimeout(timer);
  };

  const sendFeedback = async (feedback) => {
    axios.put(CHAT_FEEDBACK_ENDPOINT, {
      rating: feedback,
      unhelpful: false,
      incorrect: false,
      harmful: false,
      comment: "",
      user_suggested_answer: "",
    });
  };

  useEffect(() => {
    setTimeout(() => {
      if (rating === "positive") {
        setIsThumbsUp(true);
        setIsThumbsDown(false);
      } else if (rating === "negative") {
        setIsThumbsUp(false);
        setIsThumbsDown(true);
      } else {
        setIsThumbsUp(false);
        setIsThumbsDown(false);
      }
    }, 100);
  }, [rating]);

  return (
    <div
      className="chatLog"
      ref={chatLogRef}
      id={`navPrompt-${chat.chatPrompt.replace(/[^a-zA-Z0-9]/g, "-")}`}
    >
      <div style={styles.chatPromptMainContainer}>
        <div style={styles.chatPromptWrapper}>
          <div style={styles.chatHeader}>
            <img
              src={currentUser["picture"]}
              alt="Tech Titan Pudgy"
              style={{
                height: "24px",
                width: "24px",
                position: "relative",
                borderRadius: 150 / 2,
                overflow: "hidden",
                borderWidth: 3,
              }}
            />
            <div>Me</div>
          </div>
          <div style={styles.chatPrompt}>{chat.chatPrompt}</div>
        </div>
      </div>

      <div style={styles.botMessageMainContainer}>
        <div style={styles.botMessageWrapper}>
          <div style={styles.messageHeader}>
            <img
              src="/images/bamboohrlogo.png"
              alt="BambooHR logo"
              style={styles.avatarImage}
            />
            <div style={styles.nameContainer}>{llmLabel}</div>
            <div style={styles.buttonContainer}>
              <button
                onClick={() => handleCopyClick()}
                style={styles.iconButton}
              >
                {copiedMessageVisible ? (
                  <CheckIcon backgroundFill={"#00000000"} fill={greenShade} />
                ) : (
                  <Copy fill={isCopied ? greenShade : grayShade} />
                )}
              </button>
              <button
                onClick={() => {
                  setIsThumbsUp(!isThumbsUp);
                  if (!isThumbsUp) setIsThumbsDown(false);
                  setFeedbackModalVisible(false);
                  sendFeedback(isThumbsUp ? null : "positive");
                }}
                style={{
                  ...styles.iconButton,
                }}
              >
                <ThumbsUp fill={isThumbsUp ? greenShade : grayShade} />
              </button>

              <div>
                {feedbackModalIsVisible && (
                  <FeedbackModal
                    closeModal={() => setFeedbackModalVisible(false)}
                    chatId={chatId}
                    unhelpful={unhelpful}
                    incorrect={incorrect}
                    harmful={harmful}
                    comment={comment}
                    userSuggestedAnswer={userSuggestedAnswer}
                  />
                )}
                <button
                  onClick={() => {
                    if (!isThumbsDown) {
                      setIsThumbsDown(true);
                      setIsThumbsUp(false);
                      setFeedbackModalVisible(!feedbackModalIsVisible);
                      // Send feedback based on new state
                      sendFeedback("negative");
                    } else {
                      // If thumbs down is active, just deactivate it without showing the modal
                      setIsThumbsDown(false);
                      sendFeedback(null);
                    }
                  }}
                  style={{
                    ...styles.iconButton,
                  }}
                >
                  <ThumbsDown fill={isThumbsDown ? redShade : grayShade} />
                </button>
              </div>
            </div>
          </div>
          {chat.botMessage ? (
            <div style={styles.botMessage}>
              <BotResponse
                response={chat.botMessage}
                chatLogRef={chatLogRef}
                hasPrinted={hasPrinted}
                setHasPrinted={setHasPrinted}
                sources={chat.sources}
              />
            </div>
          ) : (
            <Loading />
          )}
        </div>
      </div>
    </div>
  );
};
